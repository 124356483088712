import React, {useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Row, Col, Form, Container} from 'react-bootstrap';
import {CBCButton, Icon, Loader} from 'shared/helpers';
import {useAppSelector} from 'store/customer';
import {WizardNavigation} from 'shared/components/Wizard/WizardNavigation';
import {NameAndDescription} from 'components/customer/Room/NameAndDescription';
import {Formik} from 'formik';
import {useRoom} from 'components/customer/Room/helpers/Room';
import {selectIsRoomLoading} from 'components/customer/Room/store/roomSlice';
import {ErrorHandler} from 'shared/components/ErrorHandler';
import {mapValues} from 'lodash';
import {CancelButton} from 'shared/components/CancelButton';
import RoomComponents from 'components/customer/Room/RoomComponents';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import styled from 'styled-components';
import {Room as RoomInterface} from 'components/customer/Room/entity/Room';

import './Room.scss';

interface RoomProps {
    tabIndex?: number;
    isMiniEditor?: boolean;
    showTabNavs?: number[];
}

export const Room = ({
    tabIndex = 0,
    isMiniEditor = false,
    showTabNavs,
}: RoomProps) => {
    const {jobId, roomId} = useParams();
    const [currentTab, setCurrentTab] = useState(tabIndex);
    const [errorsToShow, setErrorsToShow] = useState({});
    const {initialData, roomSchema, roomFormSubmitHandler, dialog} = useRoom(
        jobId,
        roomId
    ) as {
        initialData: RoomInterface;
        roomSchema: object;
        roomFormSubmitHandler: (room: RoomInterface) => void;
        dialog: React.ReactNode;
    };
    const isRoomLoading = useAppSelector(selectIsRoomLoading);
    const isSmallDevice = isDeviceSmall();

    const {button, title} = useMemo(() => {
        if (isMiniEditor) {
            return {button: 'Save', title: 'Room'};
        }

        if (roomId) {
            return {button: 'Update Room', title: 'Edit Room'};
        }

        return {
            button: 'Save Room',
            title: 'New Room',
        };
    }, [roomId, isMiniEditor]);

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={initialData}
                validationSchema={roomSchema}
                onSubmit={roomFormSubmitHandler}>
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                    isValid,
                    setTouched,
                }) => (
                    <Form
                        noValidate
                        onSubmit={(e) => {
                            setErrorsToShow({});

                            if (!isValid) {
                                e.preventDefault();
                                setErrorsToShow(errors);
                                setTouched(mapValues(errors), true);
                                return;
                            }

                            handleSubmit(e);
                        }}
                        className="room cbc-form">
                        <Loader
                            loader={isRoomLoading}
                            relative={isMiniEditor}
                        />
                        <Row>
                            <Col>
                                <ErrorHandler
                                    errors={errorsToShow}
                                    touched={touched}
                                    style={{marginTop: '15px'}}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <RoomEditorCol
                                $isMobile={isSmallDevice}
                                $isMiniEditor={isMiniEditor}>
                                {isMiniEditor ? null : (
                                    <Header>
                                        <Icon iconName="Header-Add-Room.svg" />
                                        <label>{title}</label>
                                    </Header>
                                )}

                                {isMiniEditor ? null : (
                                    <NameAndDescription
                                        values={values}
                                        handleChange={handleChange}
                                        touched={touched}
                                        errors={errors}
                                    />
                                )}

                                {isMiniEditor ? null : (
                                    <h2>Product Defaults</h2>
                                )}
                                <div className="wizard-form-container">
                                    {isMiniEditor ? null : (
                                        <div className="product-info">
                                            All selected product selections will
                                            automatically become your default
                                            option for this room.
                                        </div>
                                    )}
                                    <RoomComponents
                                        currentTab={currentTab}
                                        setCurrentTab={setCurrentTab}
                                        values={values}
                                        errors={errors}
                                        isMiniEditor={isMiniEditor}
                                        showTabNavs={showTabNavs}
                                    />
                                </div>
                            </RoomEditorCol>
                        </Row>
                        <div className="nav-and-control">
                            <Container>
                                {isMiniEditor ? null : (
                                    <Row>
                                        <Col md={{offset: 1, span: 10}}>
                                            <WizardNavigation
                                                currentTab={currentTab}
                                                setCurrentTab={setCurrentTab}
                                                max={
                                                    values.hasOwnProperty(
                                                        'isAdvancedDoor'
                                                    ) && values.isAdvancedDoor
                                                        ? 6
                                                        : 5
                                                }
                                                dots={
                                                    values.hasOwnProperty(
                                                        'isAdvancedDoor'
                                                    ) && values.isAdvancedDoor
                                                        ? 7
                                                        : 6
                                                }
                                            />
                                        </Col>
                                    </Row>
                                )}

                                <Row className="control-buttons">
                                    <Col
                                        xs={6}
                                        md={
                                            isMiniEditor
                                                ? 6
                                                : {offset: 2, span: 4}
                                        }>
                                        <CancelButton
                                            noRedirect={isMiniEditor}
                                        />
                                    </Col>
                                    <Col xs={6} md={isMiniEditor ? 6 : 4}>
                                        <CBCButton
                                            type="submit"
                                            iconName="Button-Tick.svg"
                                            className="job-button button-blue">
                                            {button}
                                        </CBCButton>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </Form>
                )}
            </Formik>
            {dialog}
        </>
    );
};

const RoomEditorCol = styled(Col)<{
    $isMobile?: boolean;
    $isMiniEditor?: boolean;
}>`
    padding-right: ${({$isMobile, $isMiniEditor}) =>
        $isMiniEditor ? '0' : $isMobile ? '10px' : '20px'};
    padding-left: ${({$isMobile, $isMiniEditor}) =>
        $isMiniEditor ? '0' : $isMobile ? '10px' : '20px'};
`;

const Header = styled.h1`
    margin-right: 0px !important;
    margin-left: 0px !important;
`;

export default Room;
